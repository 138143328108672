<template>
  <v-container grid-list-xl fluid>
    <header-title title="Estadística general"></header-title>
    <v-row class="text-center">
      <v-col cols="12" sm="3">
        <v-select
          label="Periodo y Gestión"
          :items="periodYears"
          v-model="period_years"
          item-text="periodo_gestion"
          item-value="periodo_gestion"
          @change="getEstadisticas"
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12" md="6">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              small
              color="error"
              class="mr-1"
              v-bind="attrs"
              v-on="on"
              @click="reportSummaryPdf"
              :disabled="responseData.length == 0"
            >
              PDF
            </v-btn>
          </template>
          <span>Resumen estadístico</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row class="mb-1a text-center" style="background: #eeeeee">
      <v-col cols="12" sm="6" md="3">
        <CardQuantity
          link="/institucion/report"
          bgcolor="orange accent-1"
          txtcolor="orange darken-3"
          icon="mdi-home-city"
          title="ESFM/UA"
          :quantity="quantity.institution.esfm + quantity.institution.ua"
          :description="`
            Con ${quantity.institution.esfm} ESFMs y ${quantity.institution.ua}
            UAs`"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <CardQuantity
          link="/executive/staff/report"
          bgcolor="green accent-1"
          txtcolor="green darken-2"
          icon="mdi-account-supervisor"
          title="Personal"
          :quantity="quantity.staff"
          description="Cantidad de personal administrativo"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <CardQuantity
          link="/teacher/staff/report"
          bgcolor="light-blue lighten-4"
          txtcolor="blue darken-2"
          icon="mdi-account-group"
          title="Docentes"
          :quantity="quantity.teacher"
          description="Cantidad docentes de ESFM/UA"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3">
        <CardQuantity
          link="/student/staff/report"
          bgcolor="cyan lighten-4"
          txtcolor="cyan darken-2"
          icon="mdi-card-account-details"
          title="Estudiantes"
          :quantity="quantity.student"
          description="Cantidad estudiantes de ESFM/UA"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="mt-4">
        <span class="subtitle-2 grey--text text--darken-2">
          Cantidad de ESFM/UA, Personal Administrativo, Docentes y Estudiantes
          por departamento.
        </span>
      </v-col>
    </v-row>
    <v-row style="background: #eeeeee">
      <v-col cols="12" sm="6">
        <v-card class="mx-auto" elevation="3">
          <highcharts class="chart" :options="chartInstitution"></highcharts>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="mx-auto" elevation="3">
          <highcharts
            class="chart"
            :options="chartAdministrativeStaff"
          ></highcharts>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="mx-auto" elevation="3">
          <highcharts class="chart" :options="chartTeacherStaff"></highcharts>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6">
        <v-card class="mx-auto" elevation="3">
          <highcharts class="chart" :options="chartStudentStaff"></highcharts>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="6" md="4">
        <v-card class="mx-auto" elevation="3">
          <highcharts
            class="chart"
            :options="chartGenderAdministrative"
          ></highcharts>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card class="mx-auto" elevation="3">
          <highcharts class="chart" :options="chartGenderTeacher"></highcharts>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card class="mx-auto" elevation="3">
          <highcharts class="chart" :options="chartGenderStudent"></highcharts>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import exportingInit from "highcharts/modules/exporting";

import HeaderTitle from "@/components/HeaderTitle";
import CardQuantity from "@/components/CardQuantity";
import PDFService from "../../utils/pdf";
import Storage from "../../utils/storage";
import Service from "../../services/request";
exportingInit(Highcharts);
highchartsMore(Highcharts);
export default {
  name: "department-dashboard",
  components: {
    HeaderTitle,
    CardQuantity,
  },
  data: () => ({
    message: "",
    loading: false,
    convocatoria_id: "",
    periodYears: [],
    period_years: "",
    responseData: [],
    quantity: {
      institution: {
        esfm: 0,
        ua: 0,
      },
      staff: 0,
      teacher: 0,
      student: 0,
    },
    chartInstitution: {},
    chartAdministrativeStaff: {},
    chartTeacherStaff: {},
    chartStudentStaff: {},

    chartGenderAdministrative: {},
    chartGenderTeacher: {},
    chartGenderStudent: {},
    // colorQuads: ["#689F38", "#EF6C00", "#00ACC1", "#BA68C8", "#039BE5"],
    user: null,
    context: null,
  }),
  computed: {},
  created() {
    this.context = this;
    this.user = Storage.getUser();
    if (this.user == null) {
      this.$router.replace({ name: "root" });
    }
  },
  mounted() {
    const roles = [1, 2];
    if (this.user && roles.includes(this.user.rid)) {
      this.getPeriodYearParameter();
    } else {
      this.$router.replace({ name: "root" });
    }
  },
  methods: {
    getPeriodYearParameter() {
      Service.svcPeriodYears("get")
        .then(async (response) => {
          this.periodYears = response.data[0];
          const year = this.periodYears.find((item) => item.vigente == 1);
          this.period_years = year ? year.periodo_gestion : "";
          await this.getEstadisticas();
        })
        .catch(() => {
          this.btn_loading = false;
        });
    },
    getEstadisticas() {
      this.responseData = [];
      this.quantity = {
        institution: {
          esfm: 0,
          ua: 0,
        },
        staff: 0,
        teacher: 0,
        student: 0,
      };
      Service.svcStatisticDepartment("post", {
        period_years: this.period_years,
      })
        .then((response) => {
          let genderAdmin = {
            masculino: 0,
            femenino: 0,
            otro: 0,
          };
          let genderTeacher = {
            masculino: 0,
            femenino: 0,
            otro: 0,
          };
          let genderStudent = {
            masculino: 0,
            femenino: 0,
            otro: 0,
          };
          const colors = [
            "#9CCC65",
            "#FB8C00",
            "#4DD0E1",
            /* "#BA68C8",
            "#00B0FF", */
          ];
          this.responseData = response.data;
          let institution = {
            department: [],
            countESFM: [],
            countUA: [],
          };
          const institutions = response.data[0];
          for (const key in institutions) {
            institution.department.push(institutions[key].departamento);
            institution.countESFM.push(institutions[key].esfm * 1);
            institution.countUA.push(institutions[key].ua * 1);

            this.quantity.institution.esfm += institutions[key].esfm * 1;
            this.quantity.institution.ua += institutions[key].ua * 1;
          }

          let general_staff = {
            department: [],
            executive: [],
            administrative: [],
            service: [],
          };
          const generalStaff = response.data[1];
          for (const key in generalStaff) {
            general_staff.department.push(generalStaff[key].departamento);
            general_staff.executive.push(generalStaff[key].directivo * 1);
            general_staff.administrative.push(
              generalStaff[key].administrativo * 1
            );
            general_staff.service.push(generalStaff[key].servicio * 1);

            this.quantity.staff += generalStaff[key].directivo * 1;
            this.quantity.staff += generalStaff[key].administrativo * 1;
            this.quantity.staff += generalStaff[key].servicio * 1;

            genderAdmin.masculino +=
              generalStaff[key].dmasculino * 1 +
              generalStaff[key].amasculino * 1 +
              generalStaff[key].smasculino * 1;
            genderAdmin.femenino +=
              generalStaff[key].dfemenino +
              generalStaff[key].afemenino +
              generalStaff[key].sfemenino;
            genderAdmin.otro +=
              generalStaff[key].dotro +
              generalStaff[key].aotro +
              generalStaff[key].sotro;
          }

          let teacher_staff = {
            department: [],
            quantity: [],
          };
          const teacherStaff = response.data[2];
          for (const key in teacherStaff) {
            teacher_staff.department.push(teacherStaff[key].departamento);
            teacher_staff.quantity.push(teacherStaff[key].cantidad * 1);
            this.quantity.teacher += teacherStaff[key].cantidad * 1;

            genderTeacher.masculino += teacherStaff[key].masculino;
            genderTeacher.femenino += teacherStaff[key].femenino;
            genderTeacher.otro += teacherStaff[key].otro;
          }

          let student_staff = {
            department: [],
            quantity: [],
          };
          const studentStaff = response.data[3];
          for (const key in studentStaff) {
            student_staff.department.push(studentStaff[key].departamento);
            student_staff.quantity.push(studentStaff[key].cantidad * 1);
            this.quantity.student += studentStaff[key].cantidad * 1;

            genderStudent.masculino += studentStaff[key].masculino;
            genderStudent.femenino += studentStaff[key].femenino;
            genderStudent.otro += studentStaff[key].otro;
          }
          this.chartInstitution = {
            chart: {
              type: "column",
            },
            title: {
              text: "ESFM/UA POR DEPARTAMENTO",
            },
            subtitle: {
              text: "Cantidad de Escuelas Superiores de Formación de Maestros y Unidades Académicas",
            },
            xAxis: {
              categories: institution.department,
              title: {
                text: null,
              },
            },
            yAxis: {
              min: 0,
              title: {
                text: "Cantidad",
              },
              labels: {
                overflow: "justify",
              },
            },
            tooltip: {
              formatter: function () {
                return `<b>${this.x}</b><br/>${this.series.name}: <b>${this.y}</b><br/>Total: <b>${this.point.stackTotal}</b>`;
              },
            },
            plotOptions: {
              series: {
                colorByPoint: true,
                stacking: "normal",
                dataLabels: {
                  enabled: true,
                  format: "{point.y}",
                },
              },
              column: {
                dataLabels: {
                  enabled: true,
                },
              },
            },
            legend: {
              layout: "vertical",
              align: "right",
              verticalAlign: "top",
              x: -40,
              y: 80,
              floating: true,
              borderWidth: 1,
              backgroundColor: "#fafafa",
              shadow: true,
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                name: "ESFM",
                data: institution.countESFM,
              },
              {
                name: "UA",
                data: institution.countUA,
              },
            ],
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: [
                    {
                      text: "Imprimir",
                      textKey: "printChart",
                      onclick: function () {
                        this.print();
                      },
                    },
                    {
                      text: "Descargar",
                      textKey: "downloadJPEG",
                      onclick: function () {
                        this.exportChart({
                          type: "image/jpeg",
                        });
                      },
                    },
                  ],
                },
              },
            },
          };
          this.chartAdministrativeStaff = {
            chart: {
              type: "column",
            },
            title: {
              text: "PERSONAL DE ESFM/UA POR DEPARTAMENTO",
            },
            subtitle: {
              text: "Cantidad de personal directivo, administrativo y de servicio",
            },
            xAxis: {
              categories: general_staff.department,
              title: {
                text: null,
              },
            },
            yAxis: {
              min: 0,
              title: {
                text: "Cantidad",
              },
              labels: {
                overflow: "justify",
              },
            },
            tooltip: {
              headerFormat: "{series.name}",
              pointFormat:
                '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b>',
            },
            plotOptions: {
              series: {
                borderWidth: 0,
                dataLabels: {
                  enabled: true,
                  format: "{point.y}",
                },
              },
            },
            legend: {
              layout: "vertical",
              align: "right",
              verticalAlign: "top",
              x: -40,
              y: 80,
              floating: true,
              borderWidth: 1,
              shadow: true,
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                name: "Directivo",
                data: general_staff.executive,
              },
              {
                name: "Administrativo",
                data: general_staff.administrative,
              },
              {
                name: "Servicio",
                data: general_staff.service,
              },
            ],
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: [
                    {
                      text: "Imprimir",
                      textKey: "printChart",
                      onclick: function () {
                        this.print();
                      },
                    },
                    {
                      text: "Descargar",
                      textKey: "downloadJPEG",
                      onclick: function () {
                        this.exportChart({
                          type: "image/jpeg",
                        });
                      },
                    },
                  ],
                },
              },
            },
          };
          this.chartTeacherStaff = {
            chart: {
              type: "column",
            },
            title: {
              text: "DOCENTES POR DEPARTAMENTO",
            },
            subtitle: {
              text: "Cantidad de docentes de ESFM y UA",
            },
            xAxis: {
              categories: teacher_staff.department,
              title: {
                text: null,
              },
            },
            yAxis: {
              min: 0,
              title: {
                text: "Cantidad",
              },
              labels: {
                overflow: "justify",
              },
            },
            tooltip: {
              headerFormat: "{series.name}",
              pointFormat:
                '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b>',
            },
            plotOptions: {
              column: {
                colorByPoint: true,
                dataLabels: {
                  enabled: true,
                },
              },
            },
            legend: {
              enabled: false,
              layout: "vertical",
              align: "right",
              verticalAlign: "top",
              x: -40,
              y: 80,
              floating: true,
              borderWidth: 1,
              backgroundColor: "#FFFFFF",
              shadow: true,
            },
            credits: {
              enabled: false,
            },
            series: {
              name: "Docentes",
              data: teacher_staff.quantity,
            },
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: [
                    {
                      text: "Imprimir",
                      textKey: "printChart",
                      onclick: function () {
                        this.print();
                      },
                    },
                    {
                      text: "Descargar",
                      textKey: "downloadJPEG",
                      onclick: function () {
                        this.exportChart({
                          type: "image/jpeg",
                        });
                      },
                    },
                  ],
                },
              },
            },
          };
          this.chartStudentStaff = {
            chart: {
              type: "column",
            },
            title: {
              text: "ESTUDIANTES POR DEPARTAMENTO",
            },
            subtitle: {
              text: "Cantidad de estudiantes de ESFM y UA",
            },
            xAxis: {
              categories: student_staff.department,
              title: {
                text: null,
              },
            },
            yAxis: {
              min: 0,
              title: {
                text: "Cantidad",
              },
              labels: {
                overflow: "justify",
              },
            },
            tooltip: {
              headerFormat: "{series.name}",
              pointFormat:
                '<span style="color:{point.color}">{point.name}</span>: <b>{point.y}</b>',
            },
            plotOptions: {
              column: {
                colorByPoint: true,
                dataLabels: {
                  enabled: true,
                },
              },
            },
            legend: {
              enabled: false,
              layout: "vertical",
              align: "right",
              verticalAlign: "top",
              x: -40,
              y: 80,
              floating: true,
              borderWidth: 1,
              backgroundColor: "#FFFFFF",
              shadow: true,
            },
            credits: {
              enabled: false,
            },
            series: {
              name: "Estudiantes",
              data: student_staff.quantity,
            },
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: [
                    {
                      text: "Imprimir",
                      textKey: "printChart",
                      onclick: function () {
                        this.print();
                      },
                    },
                    {
                      text: "Descargar",
                      textKey: "downloadJPEG",
                      onclick: function () {
                        this.exportChart({
                          type: "image/jpeg",
                        });
                      },
                    },
                  ],
                },
              },
            },
          };
          this.chartGenderAdministrative = {
            chart: {
              type: "pie",
            },
            title: {
              text: "PERSONAL DE ESFM/UA POR GENERO",
            },
            subtitle: {
              text: "Personal directivo, administrativo y de servicio por genero",
            },
            tooltip: {
              pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                colors: colors,
                dataLabels: {
                  enabled: true,
                  format:
                    "<b>{point.name}</b><br>Total: {point.y}<br>{point.percentage:.1f} %",
                  distance: -50,
                  filter: {
                    property: "percentage",
                    operator: ">",
                    value: 4,
                  },
                },
                showInLegend: true,
              },
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                name: "Cantidad",
                colorByPoint: true,
                data: [
                  {
                    name: "Masculino",
                    y: genderAdmin.masculino * 1,
                    sliced: true,
                    selected: true,
                  },
                  {
                    name: "Femenino",
                    y: genderAdmin.femenino * 1,
                  },
                  {
                    name: "Otros",
                    y: genderAdmin.otro * 1,
                  },
                ],
              },
            ],
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: [
                    {
                      text: "Imprimir",
                      textKey: "printChart",
                      onclick: function () {
                        this.print();
                      },
                    },
                    {
                      text: "Descargar",
                      textKey: "downloadJPEG",
                      onclick: function () {
                        this.exportChart({
                          type: "image/jpeg",
                        });
                      },
                    },
                  ],
                },
              },
            },
          };
          this.chartGenderTeacher = {
            chart: {
              type: "pie",
            },
            title: {
              text: "DOCENTES DE ESFM/UA POR GENERO",
            },
            subtitle: {
              text: "Docentes por genero",
            },
            tooltip: {
              pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                colors: colors,
                dataLabels: {
                  enabled: true,
                  format:
                    "<b>{point.name}</b><br>Total: {point.y}<br>{point.percentage:.1f} %",
                  distance: -50,
                  filter: {
                    property: "percentage",
                    operator: ">",
                    value: 4,
                  },
                },
                showInLegend: true,
              },
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                name: "Cantidad",
                colorByPoint: true,
                data: [
                  {
                    name: "Masculino",
                    y: genderTeacher.masculino * 1,
                    sliced: true,
                    selected: true,
                  },
                  {
                    name: "Femenino",
                    y: genderTeacher.femenino * 1,
                  },
                  {
                    name: "Otros",
                    y: genderTeacher.otro * 1,
                  },
                ],
              },
            ],
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: [
                    {
                      text: "Imprimir",
                      textKey: "printChart",
                      onclick: function () {
                        this.print();
                      },
                    },
                    {
                      text: "Descargar",
                      textKey: "downloadJPEG",
                      onclick: function () {
                        this.exportChart({
                          type: "image/jpeg",
                        });
                      },
                    },
                  ],
                },
              },
            },
          };
          this.chartGenderStudent = {
            chart: {
              type: "pie",
            },
            title: {
              text: "ESTUDIANTES DE ESFM/UA POR GENERO",
            },
            subtitle: {
              text: "Estudiantes por genero",
            },
            tooltip: {
              pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
            },
            plotOptions: {
              pie: {
                allowPointSelect: true,
                cursor: "pointer",
                colors: colors,
                dataLabels: {
                  enabled: true,
                  format:
                    "<b>{point.name}</b><br>Total: {point.y}<br>{point.percentage:.1f} %",
                  distance: -50,
                  filter: {
                    property: "percentage",
                    operator: ">",
                    value: 4,
                  },
                },
                showInLegend: true,
              },
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                name: "Cantidad",
                colorByPoint: true,
                data: [
                  {
                    name: "Masculino",
                    y: genderStudent.masculino * 1,
                    sliced: true,
                    selected: true,
                  },
                  {
                    name: "Femenino",
                    y: genderStudent.femenino * 1,
                  },
                  {
                    name: "Otros",
                    y: genderStudent.otro * 1,
                  },
                ],
              },
            ],
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: [
                    {
                      text: "Imprimir",
                      textKey: "printChart",
                      onclick: function () {
                        this.print();
                      },
                    },
                    {
                      text: "Descargar",
                      textKey: "downloadJPEG",
                      onclick: function () {
                        this.exportChart({
                          type: "image/jpeg",
                        });
                      },
                    },
                  ],
                },
              },
            },
          };
        })
        .catch(() => {
          console.error("datos no encontrado");
        });
    },
    reportSummaryPdf() {
      PDFService.pdfSummaryDepartment(this.responseData);
    },
  },
};
</script>
<style scoped>
.head-color-1 {
  background-color: #616161;
}
.head-color-1 th {
  color: white !important;
}
.head-color-2 {
  background-color: #e0e0e0;
}
</style>
